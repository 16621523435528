<template>
	<div class="slider-img">
		<Swiper
			v-if="imgCount()"
			class="swiper"
			:options="page.swiperOption"
		>
			<SwiperSlide
				v-for="(image , n) in banner"
				:key="n"
			>
				<img
					v-show="page.imgLoad"
					id="banner-img"
					:src="image"
					width="100%"
					:height="findHeight()"
					class="slider-img-banner"
					@load="page.imgLoad = true"
				>

				<v-skeleton-loader
					v-if="!page.imgLoad"
					width="100%"
					:height="findHeight()"
					type="image"
				/>
			</SwiperSlide>
		</Swiper>

		<div
			v-else
			class="normal-img"
		>
			<img
				v-show="page.imgLoad"
				id="banner-img"
				:src="banner[0]"
				width="100%"
				:height="findHeight()"
				class="slider-img-banner"
				@load="page.imgLoad = true"
			>

			<v-skeleton-loader
				v-if="!page.imgLoad"
				width="100%"
				:height="findHeight()"
				type="image"
			/>
		</div>
	</div>
</template>

<script>
// import BlurImage from "components/extra/BlurImage";

export default {
	// components: {
	// BlurImage,
	// },
	props: {
		// eslint-disable-next-line vue/require-default-prop
		banner: { type: Array, required: false },
		// eslint-disable-next-line vue/require-default-prop
		tiny: { type: Array, required: false },
	},
	data() {
		return {
			page: {
				bannerImg: this.banner,
				imgLoad: false,
				swiperOption: {
					init: true,
					slidesPerView: "auto",
					centeredSlides: true,
					spaceBetween: 30,
					loop: true,
					loopAdditionalSlides: 1,
					preloadImages: true,
					lazy: {
						loadPrevNext: true,
						loadOnTransitionStart: true,
					},
					watchSlidesVisibility: true,
					initialSlide: 0,
					autoplay: {
						delay: 3000,
						disableOnInteraction: false,
						autoplayDisableOnInteraction: false,
					},
					breakpoints: {
						1024: {
							spaceBetween: 30,
						},
						768: {
							spaceBetween: 30,
						},
						640: {
							spaceBetween: 10,
						},
						320: {
							spaceBetween: 10,
						},
					},
				},
			},
		};
	},
	mounted() {
		this.findHeight();
	},
	methods: {
		imgCount() {
			return Object.keys(this.banner).length > 1;
		},
		findHeight() {
			const width = 1250;
			const height = 648;
			const oldR = height / width;
			let newH = oldR * window.screen.width;
			if (newH > 648) {
				newH = 648;
			}
			return newH;
		},
	},
};
</script>

<style scoped>
	.swiper-slide {
		width: 90%;
		display: flex;
		justify-content: center;
		overflow: hidden;
		border-radius: 20px;
		max-height: 648px;
  	}
	.normal-img {
		width: 100%;
		display: flex;
		justify-content: center;
		overflow: hidden;
		border-radius: 0px;
		height: auto;
		max-height: 648px;
	}

</style>

<style lang="scss">
	.v-skeleton-loader__image{
		height: 648px !important;
	}
</style>
