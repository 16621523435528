<template>
	<div class="tourPlan">
		<v-timeline
			align-top
			dense
		>
			<v-timeline-item
				v-for="(name, i) in tour.name"
				:key="i"
				:icon="icon(tour.icon[i])"
				fill-dot
			>
				<div class="planContent">
					<div class="title">
						<p>
							<span class="headline">
								Day {{ displayLoopCount(i) }}
							</span>
							-
							<span class="primary--text title">{{ name }}</span>
						</p>
						<p class="content subtitle-1 font-weight-light">
							<!-- eslint-disable-next-line vue/no-v-html -->
							<span v-html="tour.content[i]" />
						</p>
						<v-divider class="my-2" />
					</div>
				</div>
			</v-timeline-item>
		</v-timeline>
	</div>
</template>

<script>
export default {
	props: {
		// eslint-disable-next-line vue/require-default-prop
		tour: { type: Object },
	},
	methods: {
		displayLoopCount(index) {
			return parseInt(index, 10) + 1;
		},
		icon(icon) {
			let iconName;
			switch (icon) {
			case "Car":
				iconName = "mdi-car";
				break;

			case "Flight":
				iconName = "mdi-airplane";
				break;

			case "sightseeing":
				iconName = "mdi-binoculars";
				break;

			case "Hotel":
				iconName = "mdi-home";
				break;

			case "Cruise":
				iconName = "mdi-ferry";
				break;

			case "Safari":
				iconName = "mdi-pine-tree";
				break;

			default:
				iconName = "mdi-home";
				break;
			}
			return iconName;
		},
	},
};
</script>
