<template>
	<div class="prices py-8">
		<v-row>
			<v-col cols="12">
				<p class="primary--text headline">
					Prices
				</p>
			</v-col>
			<v-col cols="12">
				<p class="font-weight-light">
					<!-- eslint-disable-next-line vue/no-v-html -->
					<span v-html="price" />
				</p>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	props: {
		// eslint-disable-next-line vue/require-default-prop
		price: { type: String },
	},
};
</script>
