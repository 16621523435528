<template>
	<div class="gallery py-8">
		<v-dialog
			v-model="overlay"
			max-width="500"
		>
			<v-card>
				<div
					class="gallery-img-full"
				>
					<img
						:src="overlayImg"
						alt=""
					>
				</div>
				<v-card-actions>
					<v-btn
						color="primary"
						text
						icon
						@click="prev"
					>
						<v-icon>
							mdi-chevron-left
						</v-icon>
					</v-btn>
					{{ current+1 }} / {{ gallery.length }}
					<v-btn
						color="primary"
						text
						icon
						@click="next"
					>
						<v-icon>
							mdi-chevron-right
						</v-icon>
					</v-btn>
					<v-spacer />
					<v-btn
						color="primary"
						text
						@click="overlay = false"
					>
						Close
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row>
			<v-col
				v-for="(img,n) in gallery"
				:key="n"
				cols="12"
				md="4"
				xl="4"
			>
				<img
					:src="img"
					class="gallery-img gallery-img-zoom"
					@click="() => {
						current = n, overlayImg = img; overlay = true;
					}"
				>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	props: {
		// eslint-disable-next-line vue/require-default-prop
		gallery: { type: Array },
	},
	data() {
		return {
			overlay: false,
			overlayImg: null,
			current: 0,
		};
	},
	methods: {
		next() {
			if (this.current < (this.gallery.length - 1)) {
				this.current += 1;
				this.overlayImg = this.gallery[this.current];
			} else {
				this.current = 0;
				this.overlayImg = this.gallery[this.current];
			}
		},
		prev() {
			if (this.current > 0) {
				this.current -= 1;
				this.overlayImg = this.gallery[this.current];
			} else {
				this.current = this.gallery.length - 1;
				this.overlayImg = this.gallery[this.current];
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.gallery-img{
		width: 100%;
		height: auto;
	}
	.gallery-img-zoom{
		transform: scale(1);
		transition: all 0.3s ease-in-out;
		@media (min-width: 960px) {
			&:hover {
				transform: scale(1.075);
			}
		}
	}
</style>
