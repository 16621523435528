<template>
	<div class="incExc py-8">
		<v-row v-if="include">
			<v-col cols="12">
				<p class="primary--text headline">
					Include
				</p>
			</v-col>
			<v-col cols="12">
				<p class="font-weight-light">
					<!-- eslint-disable-next-line vue/no-v-html -->
					<span v-html="include" />
				</p>
			</v-col>
			<v-divider class="my-8" />
		</v-row>

		<v-row v-if="notInclude">
			<v-col cols="12">
				<p class="primary--text headline">
					Exclude
				</p>
			</v-col>
			<v-col cols="12">
				<p class="font-weight-light">
					<!-- eslint-disable-next-line vue/no-v-html -->
					<span v-html="notInclude" />
				</p>
			</v-col>
			<v-divider class="my-8" />
		</v-row>

		<v-row v-if="others">
			<v-col cols="12">
				<p class="primary--text headline">
					Others
				</p>
			</v-col>
			<v-col cols="12">
				<p class="font-weight-light">
					<!-- eslint-disable-next-line vue/no-v-html -->
					<span v-html="others" />
				</p>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	props: {
		// eslint-disable-next-line vue/require-default-prop
		include: { type: String },
		// eslint-disable-next-line vue/require-default-prop
		notInclude: { type: String },
		// eslint-disable-next-line vue/require-default-prop
		others: { type: String },
	},
};
</script>
