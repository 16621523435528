<template>
	<div
		v-resize="onResize"
		class="tour-details"
	>
		<div
			v-if="!apiRes()"
			class="text-center"
		>
			<v-overlay
				:value="true"
				:opacity="1"
			>
				<v-progress-circular
					indeterminate
					size="64"
				/>
			</v-overlay>
		</div>
		<div
			v-else
			class="mainContant"
		>
			<div
				v-if="!error404"
				class="tourData"
			>
				<div class="banner primaryBG">
					<div class="slider">
						<Banner
							:banner="page.data.information.bannerImages"
							:tiny="page.data.information.tinyImage"
						/>
					</div>
				</div>

				<div class="tourDetails secondaryBG py-10">
					<v-container>
						<v-row>
							<v-col
								v-if="!isMobile"
								cols="12"
							>
								<v-tabs
									v-model="page.tab"
									background-color="transparent"
									color="primary"
								>
									<v-tabs-slider />

									<v-tab
										v-if="page.data.information"
										href="#tab-1"
									>
										<v-icon class="mr-4">
											mdi-information-variant
										</v-icon>
										Information
									</v-tab>

									<v-tab
										v-if="page.data.tourPlan"
										href="#tab-2"
									>
										<v-icon class="mr-4">
											mdi-google-maps
										</v-icon>
										Tour Plan
									</v-tab>

									<v-tab
										v-if="page.data.gallery.length > 0"
										href="#tab-3"
									>
										<v-icon class="mr-4">
											mdi-folder-multiple-image
										</v-icon>
										Gallery
									</v-tab>

									<v-tab
										v-if="page.data.include || page.data.notInclude || page.data.others"
										href="#tab-4"
									>
										<v-icon class="mr-4">
											mdi-all-inclusive
										</v-icon>
										Include & Exclude
									</v-tab>

									<v-tab
										v-if="page.data.price"
										href="#tab-5"
									>
										<v-icon class="mr-4">
											mdi-wallet
										</v-icon>
										Prices
									</v-tab>
								</v-tabs>
								<v-divider />
							</v-col>

							<v-col
								v-if="!isMobile"
								cols="12"
								md="8"
							>
								<v-tabs-items
									v-model="page.tab"
									class="tabs-item"
								>
									<v-tab-item
										value="tab-1"
									>
										<Information
											:information="page.data.information"
											:map="page.data.location"
										/>
									</v-tab-item>

									<v-tab-item
										value="tab-2"
									>
										<TourPlan :tour="page.data.tourPlan" />
									</v-tab-item>

									<v-tab-item
										value="tab-3"
									>
										<Gallery :gallery="page.data.gallery" />
									</v-tab-item>

									<v-tab-item
										value="tab-4"
									>
										<IncludeExclude
											:include="page.data.include"
											:not-include="page.data.notInclude"
											:others="page.data.others"
										/>
									</v-tab-item>

									<v-tab-item
										value="tab-5"
									>
										<Prices :price="page.data.price" />
									</v-tab-item>
								</v-tabs-items>
							</v-col>

							<v-col
								v-else
								cols="12"
							>
								<v-expansion-panels
									v-model="page.panel"
									multiple
								>
									<v-expansion-panel
										v-if="page.data.information"
										key="0"
									>
										<v-expansion-panel-header>
											<span>
												<v-icon class="mr-4">
													mdi-information-variant
												</v-icon>
												Information
											</span>
										</v-expansion-panel-header>
										<v-expansion-panel-content>
											<Information
												:information="page.data.information"
												:map="page.data.location"
											/>
										</v-expansion-panel-content>
									</v-expansion-panel>

									<v-expansion-panel
										v-if="page.data.tourPlan"
										key="1"
									>
										<v-expansion-panel-header>
											<span>
												<v-icon class="mr-4">
													mdi-google-maps
												</v-icon>
												Tour Plan
											</span>
										</v-expansion-panel-header>
										<v-expansion-panel-content>
											<TourPlan :tour="page.data.tourPlan" />
										</v-expansion-panel-content>
									</v-expansion-panel>

									<v-expansion-panel
										v-if="page.data.gallery.length > 0"
										key="2"
									>
										<v-expansion-panel-header>
											<span>
												<v-icon class="mr-4">
													mdi-folder-multiple-image
												</v-icon>
												Gallery
											</span>
										</v-expansion-panel-header>
										<v-expansion-panel-content>
											<Gallery :gallery="page.data.gallery" />
										</v-expansion-panel-content>
									</v-expansion-panel>

									<v-expansion-panel
										v-if="page.data.include || page.data.notInclude || page.data.others"
										key="3"
									>
										<v-expansion-panel-header>
											<span>
												<v-icon class="mr-4">
													mdi-all-inclusive
												</v-icon>
												Include & Exclude
											</span>
										</v-expansion-panel-header>
										<v-expansion-panel-content>
											<IncludeExclude
												:include="page.data.include"
												:not-include="page.data.notInclude"
												:others="page.data.others"
											/>
										</v-expansion-panel-content>
									</v-expansion-panel>

									<v-expansion-panel
										v-if="page.data.price"
										key="4"
									>
										<v-expansion-panel-header>
											<span>
												<v-icon class="mr-4">
													mdi-wallet
												</v-icon>
												Prices
											</span>
										</v-expansion-panel-header>
										<v-expansion-panel-content>
											<Prices :price="page.data.price" />
										</v-expansion-panel-content>
									</v-expansion-panel>
								</v-expansion-panels>
							</v-col>

							<v-col
								cols="12"
								md="4"
								class="enquiry-form"
							>
								<v-card class="enquiry">
									<v-card-title class="primary--text">
										Enquiry Tour / Get Quote
									</v-card-title>
									<v-card-text>
										<v-form
											ref="enquiryForm"
											lazy-validation
										>
											<v-row>
												<v-col cols="12">
													<v-text-field
														v-model="page.enquiry.form.name"
														label="Name *"
														clearable
														:rules="page.enquiry.validation.requiredField"
													/>
												</v-col>
												<v-col cols="12">
													<v-text-field
														v-model="page.enquiry.form.email"
														label="Email *"
														placeholder="yourname@domain.com"
														clearable
														:rules="page.enquiry.validation.requiredField && page.enquiry.validation.emailValid"
													/>
												</v-col>
												<v-col cols="12">
													<v-text-field
														v-model="page.enquiry.form.mobile"
														label="Mobile *"
														placeholder="+91xxxxxxxxxx"
														clearable
														:rules="page.enquiry.validation.requiredField && page.enquiry.validation.mobileValid"
													/>
												</v-col>
												<v-col cols="12">
													<v-textarea
														v-model="page.enquiry.form.dummyMessage"
														label="Message *"
														clearable
														:rules="page.enquiry.validation.requiredField"
													/>
												</v-col>
												<v-expand-transition>
													<v-col
														v-if="page.enquiry.res"
														cols="12"
													>
														<v-alert
															dense
															text
															type="success"
														>
															{{ page.enquiry.res }}
														</v-alert>
													</v-col>
												</v-expand-transition>
												<v-col cols="12">
													<v-btn
														class="primary"
														:loading="page.enquiry.loading"
														@click="enquirySubmit"
													>
														Send
														<v-icon class="ml-2">
															mdi-send
														</v-icon>
													</v-btn>
												</v-col>
											</v-row>
										</v-form>
									</v-card-text>
								</v-card>
							</v-col>
						</v-row>
						<v-divider class="my-16" />
						<v-row>
							<v-col cols="12">
								<div class="suggest-tour title my-2">
									<p class="display-3 font-weight-bold text-center">
										Other Suggestions
									</p>
								</div>
							</v-col>
							<v-col
								v-for="(tour , index) in tours.data"
								:key="index"
								cols="12"
								sm="12"
								md="4"
								lg="4"
								xl="4"
							>
								<TourCard :tour-data="tour" />
							</v-col>
						</v-row>
					</v-container>
				</div>
			</div>
			<div
				v-else
				class="tourData"
			>
				<Error404 />
			</div>
		</div>
	</div>
</template>

<script>
import Banner from "components/Banner";
import Error404 from "components/extra/error404";
import TourCard from "components/TourCard";
import Gallery from "components/tourDetails/Gallery";
import IncludeExclude from "components/tourDetails/IncludeExclude";
import Information from "components/tourDetails/Information";
import Prices from "components/tourDetails/Prices";
import TourPlan from "components/tourDetails/TourPlan";

import { api, baseMixins, toursMixin } from "mixins";

export default {
	components: {
		Banner,
		Information,
		TourPlan,
		Gallery,
		IncludeExclude,
		Prices,
		Error404,
		TourCard,
	},
	mixins: [baseMixins, api, toursMixin],
	data() {
		return {
			error404: false,
			page: {
				load: {
					tour: false,
				},
				tab: null,
				panel: [],
				url: null,
				data: {
					information: {},
					tourPlan: {},
					gallery: [],
					location: null,
					include: null,
					notInclude: null,
					others: null,
					price: null,
				},
				enquiry: {
					loading: false,
					form: {
						name: null,
						email: null,
						mobile: null,
						dummyMessage: null,
						message: null,
					},
					res: null,
					validation: {
						requiredField: [(v) => !!v || "This Field is Required"],
						emailValid: [(v) => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || "Please enter valid Email ID"],
						mobileValid: [(v) => /^\+[0-9]*$/.test(v) || "please enter valid Mobile Number"],
					},
				},
			},
		};
	},
	watch: {
		$route() {
			this.checkProps();
			this.getSuggestion();
			window.scrollTo(0, 0);
		},
	},
	created() {
		this.checkProps();
		this.getSuggestion();
		window.scrollTo(0, 0);
	},
	methods: {
		apiRes() {
			return this.page.load.tour;
		},
		checkProps() {
			if (this.$route.params.name) {
				this.page.url = this.$route.params.name;
				this.getData();
			} else {
				this.error404 = true;
			}
		},
		getData() {
			if (this.baseCheckBeforeCallApiFunction()) {
				this.page.load.tour = false;
				this.callApi(this.$axios, "f/tour/view", { url: this.page.url }, { apiKey: "ev-f-to-det-view" }).then((response) => {
					if (response && !Array.isArray(response)) {
						this.page.data.information = JSON.parse(response.information);
						this.page.data.tourPlan = JSON.parse(response.tourPlan);
						this.page.data.location = JSON.parse(response.location);
						this.page.data.include = JSON.parse(response.include);
						this.page.data.notInclude = JSON.parse(response.notInclude);
						this.page.data.others = JSON.parse(response.others);
						this.page.data.price = JSON.parse(response.price);
						this.page.data.gallery = JSON.parse(response.gallery);
						this.page.data.url = response.url;
						this.error404 = false;
					} else {
						this.error404 = true;
					}
					this.page.load.tour = true;
				});
			}
		},
		clearForm() {
			this.page.enquiry.form.name = null;
			this.page.enquiry.form.email = null;
			this.page.enquiry.form.mobile = null;
			this.page.enquiry.form.message = null;
			this.page.enquiry.form.dummyMessage = null;
		},
		enquirySubmit() {
			if (this.baseCheckBeforeCallApiFunction("enquiryForm")) {
				this.page.enquiry.loading = true;
				this.page.enquiry.form.message = `This Enquiry from - ${this.page.data.information.name}, Message:  ${this.page.enquiry.form.dummyMessage}`;
				this.callApi(this.$axios, "f/contact/set", this.page.enquiry.form, { apiKey: "ev-f-con-set" }).then((response) => {
					if (response) {
						this.page.enquiry.res = response.msg;
					}
					this.clearForm();
					this.$refs.enquiryForm.resetValidation();
					this.page.enquiry.loading = false;
				});
			}
		},
		getSuggestion() {
			this.tours.search.random = this.$route.params.name;
			this.getTours();
		},
	},
};
</script>

<style lang="scss" scoped>
	.tabs-item{
		background-color: transparent;
	}
	.enquiry{
		background-color: transparent;
		border: 1px solid var(--v-bg1-base);
	}
	.enquiry-form{
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		align-self: start;
	}
</style>
