<template>
	<div class="information">
		<div class="title">
			<p class="display-1 mt-8">
				{{ information.name }}
			</p>
		</div>

		<div
			v-if="information.price"
			class="price"
		>
			<span class="prefix">
				{{ information.pricePrefix }}
			</span>
			<span class="symbol headline primary--text">
				{{ information.priceSign }}
			</span>
			<span class="amt headline primary--text">
				{{ information.price }}
			</span>
			<span class="sufix">
				{{ information.priceSuffix }}
			</span>
		</div>

		<v-divider class="my-4" />

		<div class="tourInfo">
			<p class="font-weight-light">
				<!-- eslint-disable-next-line vue/no-v-html -->
				<span v-html="information.details" />
			</p>
		</div>

		<div class="tags d-flex flex-row align-center">
			<!-- Total Days -->
			<div
				v-if="information.totalDays"
				class="days d-flex flex-row mr-4 align-center"
			>
				<v-tooltip bottom>
					<template
						#activator="{
							on, attrs
						}"
					>
						<span
							v-bind="attrs"
							v-on="on"
						>
							<v-icon
								color="primary"
								size="36"
							>
								mdi-calendar-check
							</v-icon>
							<span class="ml-4 primary--text">{{ information.totalDays }} Days</span>
						</span>
					</template>
					<span>{{ information.totalDays }} Days</span>
				</v-tooltip>
			</div>
			<!-- Hotel -->
			<div
				v-if="information.hotel"
				class="hotel d-flex flex-row mr-4"
			>
				<v-tooltip bottom>
					<template
						#activator="{
							on, attrs
						}"
					>
						<span
							v-bind="attrs"
							v-on="on"
						>
							<v-icon
								color="primary"
								size="36"
							>
								mdi-home
							</v-icon>
						</span>
					</template>
					<span>Hotel</span>
				</v-tooltip>
			</div>
			<!-- Car -->
			<div
				v-if="information.car"
				class="car d-flex flex-row mr-4"
			>
				<v-tooltip bottom>
					<template
						#activator="{
							on, attrs
						}"
					>
						<span
							v-bind="attrs"
							v-on="on"
						>
							<v-icon
								color="primary"
								size="36"
							>
								mdi-car
							</v-icon>
						</span>
					</template>
					<span>Car</span>
				</v-tooltip>
			</div>
			<!-- Sightseeing -->
			<div
				v-if="information.sigthsceen"
				class="sightseeing d-flex flex-row mr-4"
			>
				<v-tooltip bottom>
					<template
						#activator="{
							on, attrs
						}"
					>
						<span
							v-bind="attrs"
							v-on="on"
						>
							<v-icon
								color="primary"
								size="36"
							>
								mdi-binoculars
							</v-icon>
						</span>
					</template>
					<span>Sightseeing</span>
				</v-tooltip>
			</div>
			<!-- Meals -->
			<div
				v-if="information.meals"
				class="meals d-flex flex-row mr-4"
			>
				<v-tooltip bottom>
					<template
						#activator="{
							on, attrs
						}"
					>
						<span
							v-bind="attrs"
							v-on="on"
						>
							<v-icon
								color="primary"
								size="36"
							>
								mdi-silverware
							</v-icon>
						</span>
					</template>
					<span>Meals</span>
				</v-tooltip>
			</div>
			<!-- Flight -->
			<div
				v-if="information.flight"
				class="flight d-flex flex-row mr-4"
			>
				<v-tooltip bottom>
					<template
						#activator="{
							on, attrs
						}"
					>
						<span
							v-bind="attrs"
							v-on="on"
						>
							<v-icon
								color="primary"
								size="36"
							>
								mdi-airplane
							</v-icon>
						</span>
					</template>
					<span>Flight</span>
				</v-tooltip>
			</div>
			<!-- Visa -->
			<div
				v-if="information.visa"
				class="visa d-flex flex-row mr-4"
			>
				<v-tooltip bottom>
					<template
						#activator="{
							on, attrs
						}"
					>
						<span
							v-bind="attrs"
							v-on="on"
						>
							<v-icon
								color="primary"
								size="36"
							>
								mdi-passport
							</v-icon>
						</span>
					</template>
					<span>Visa</span>
				</v-tooltip>
			</div>
			<!-- Cruise -->
			<div
				v-if="information.cruise"
				class="cruise d-flex flex-row mr-4"
			>
				<v-tooltip bottom>
					<template
						#activator="{
							on, attrs
						}"
					>
						<span
							v-bind="attrs"
							v-on="on"
						>
							<v-icon
								color="primary"
								size="36"
							>
								mdi-ferry
							</v-icon>
						</span>
					</template>
					<span>Cruise</span>
				</v-tooltip>
			</div>
			<!-- Safari -->
			<div
				v-if="information.safari"
				class="safari d-flex flex-row mr-4"
			>
				<v-tooltip bottom>
					<template
						#activator="{
							on, attrs
						}"
					>
						<span
							v-bind="attrs"
							v-on="on"
						>
							<v-icon
								color="primary"
								size="36"
							>
								mdi-pine-tree
							</v-icon>
						</span>
					</template>
					<span>Safari</span>
				</v-tooltip>
			</div>
			<!-- Discount -->
			<div
				v-if="information.dealsDiscount"
				class="visa d-flex flex-row mr-4"
			>
				<v-tooltip bottom>
					<template
						#activator="{
							on, attrs
						}"
					>
						<span
							v-bind="attrs"
							v-on="on"
						>
							<v-icon
								color="primary"
								size="36"
							>
								mdi-tag
							</v-icon>
						</span>
					</template>
					<span>Special Offer</span>
				</v-tooltip>
			</div>
		</div>

		<v-divider class="my-4" />

		<div class="location-url">
			<iframe
				:src="map"
				width="100%"
				height="450"
				style="border:0;"
				allowfullscreen=""
				loading="lazy"
			/>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		// eslint-disable-next-line vue/require-default-prop
		information: { type: Object },
		// eslint-disable-next-line vue/require-default-prop
		map: { type: String },
	},
};
</script>

<style lang="scss" scoped>
	.tags{
		flex-flow: wrap;
	}
</style>
