var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"information"},[_c('div',{staticClass:"title"},[_c('p',{staticClass:"display-1 mt-8"},[_vm._v(" "+_vm._s(_vm.information.name)+" ")])]),(_vm.information.price)?_c('div',{staticClass:"price"},[_c('span',{staticClass:"prefix"},[_vm._v(" "+_vm._s(_vm.information.pricePrefix)+" ")]),_c('span',{staticClass:"symbol headline primary--text"},[_vm._v(" "+_vm._s(_vm.information.priceSign)+" ")]),_c('span',{staticClass:"amt headline primary--text"},[_vm._v(" "+_vm._s(_vm.information.price)+" ")]),_c('span',{staticClass:"sufix"},[_vm._v(" "+_vm._s(_vm.information.priceSuffix)+" ")])]):_vm._e(),_c('v-divider',{staticClass:"my-4"}),_c('div',{staticClass:"tourInfo"},[_c('p',{staticClass:"font-weight-light"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.information.details)}})])]),_c('div',{staticClass:"tags d-flex flex-row align-center"},[(_vm.information.totalDays)?_c('div',{staticClass:"days d-flex flex-row mr-4 align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var on = ref.on;
					var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary","size":"36"}},[_vm._v(" mdi-calendar-check ")]),_c('span',{staticClass:"ml-4 primary--text"},[_vm._v(_vm._s(_vm.information.totalDays)+" Days")])],1)]}}],null,false,3428244997)},[_c('span',[_vm._v(_vm._s(_vm.information.totalDays)+" Days")])])],1):_vm._e(),(_vm.information.hotel)?_c('div',{staticClass:"hotel d-flex flex-row mr-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var on = ref.on;
					var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary","size":"36"}},[_vm._v(" mdi-home ")])],1)]}}],null,false,842652677)},[_c('span',[_vm._v("Hotel")])])],1):_vm._e(),(_vm.information.car)?_c('div',{staticClass:"car d-flex flex-row mr-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var on = ref.on;
					var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary","size":"36"}},[_vm._v(" mdi-car ")])],1)]}}],null,false,1940564090)},[_c('span',[_vm._v("Car")])])],1):_vm._e(),(_vm.information.sigthsceen)?_c('div',{staticClass:"sightseeing d-flex flex-row mr-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var on = ref.on;
					var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary","size":"36"}},[_vm._v(" mdi-binoculars ")])],1)]}}],null,false,2455792858)},[_c('span',[_vm._v("Sightseeing")])])],1):_vm._e(),(_vm.information.meals)?_c('div',{staticClass:"meals d-flex flex-row mr-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var on = ref.on;
					var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary","size":"36"}},[_vm._v(" mdi-silverware ")])],1)]}}],null,false,1916870300)},[_c('span',[_vm._v("Meals")])])],1):_vm._e(),(_vm.information.flight)?_c('div',{staticClass:"flight d-flex flex-row mr-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var on = ref.on;
					var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary","size":"36"}},[_vm._v(" mdi-airplane ")])],1)]}}],null,false,522565830)},[_c('span',[_vm._v("Flight")])])],1):_vm._e(),(_vm.information.visa)?_c('div',{staticClass:"visa d-flex flex-row mr-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var on = ref.on;
					var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary","size":"36"}},[_vm._v(" mdi-passport ")])],1)]}}],null,false,3787957890)},[_c('span',[_vm._v("Visa")])])],1):_vm._e(),(_vm.information.cruise)?_c('div',{staticClass:"cruise d-flex flex-row mr-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var on = ref.on;
					var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary","size":"36"}},[_vm._v(" mdi-ferry ")])],1)]}}],null,false,3136960944)},[_c('span',[_vm._v("Cruise")])])],1):_vm._e(),(_vm.information.safari)?_c('div',{staticClass:"safari d-flex flex-row mr-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var on = ref.on;
					var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary","size":"36"}},[_vm._v(" mdi-pine-tree ")])],1)]}}],null,false,2268321875)},[_c('span',[_vm._v("Safari")])])],1):_vm._e(),(_vm.information.dealsDiscount)?_c('div',{staticClass:"visa d-flex flex-row mr-4"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
					var on = ref.on;
					var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary","size":"36"}},[_vm._v(" mdi-tag ")])],1)]}}],null,false,3719402616)},[_c('span',[_vm._v("Special Offer")])])],1):_vm._e()]),_c('v-divider',{staticClass:"my-4"}),_c('div',{staticClass:"location-url"},[_c('iframe',{staticStyle:{"border":"0"},attrs:{"src":_vm.map,"width":"100%","height":"450","allowfullscreen":"","loading":"lazy"}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }