<template>
	<div
		class="tourCard"
	>
		<v-card
			ref="tourVCard"
			class="mx-auto my-4 cardBG tourCard-zoom"
		>
			<div
				v-if="tour.information.dealsDiscount"
				class="discount-tag"
			>
				<span>Discount</span>
			</div>
			<div
				v-if="skeletonDisplay"
				class="cardImage"
				@click="detailsView"
			>
				<img
					v-show="load"
					:src="tour.information.bannerImages[0]"
					class="tourImg"
					@load="load = true"
				>
				<v-skeleton-loader
					v-if="!load"
					class="mx-auto"
					:height="findHeight()"
					type="image"
				/>
			</div>

			<div
				v-else
				class="cardImage"
				@click="detailsView"
			>
				<img
					:src="tour.information.bannerImages[0]"
					class="tourImg"
				>
			</div>
			<v-card-title
				class="tourTitle"
				@click="detailsView"
			>
				<span>
					{{ tour.information.name }}
				</span>
			</v-card-title>
			<v-card-text
				class="card-content"
			>
				<div
					v-if="tour.information.price"
					class="headline tourPrice"
				>
					<span class="prefix">
						{{ tour.information.pricePrefix }}
					</span>
					<span class="symbol headline primary--text">
						{{ tour.information.priceSign }}
					</span>
					<span class="amt headline primary--text">
						{{ tour.information.price }}
					</span>
					<span class="sufix">
						{{ tour.information.priceSuffix }}
					</span>
				</div>

				<div
					v-else
					class="headline tourPrice"
				>
					<span class="headline primary--text">
						<router-link
							to="/#contact"
							class="text-decoration-none"
						>
							Get Quote
						</router-link>
					</span>
				</div>

				<div class="tourDetails-card">
					<!-- eslint-disable-next-line vue/no-v-html -->
					<span v-html="tour.information.details" />
				</div>
				<v-divider class="my-4" />

				<v-icon
					color="primary"
					size="36"
				>
					mdi-calendar-check
				</v-icon>
				<span class="ml-4 primary--text">{{ tour.information.totalDays }} Days</span>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>

export default {
	props: {
		// eslint-disable-next-line vue/require-default-prop
		tourData: { type: Object },
		skeleton: { type: Boolean, default: true },
	},
	data() {
		return {
			tour: this.dataModify(this.tourData),
			skeletonDisplay: this.skeleton,
			load: false,
		};
	},
	watch: {
		tourData() {
			this.tour = this.dataModify(this.tourData);
		},
	},
	methods: {
		detailsView() {
			this.$router.push({ path: `/package/${this.tour.url}` });
		},
		dataModify(val) {
			const tours = {};
			tours.information = JSON.parse(val.information);
			tours.url = val.url;
			return tours;
		},
		findHeight() {
			let newH = 0;
			const width = 1250;
			const height = 648;
			const oldR = height / width;
			newH = oldR * 367;
			if (newH > 648) {
				newH = 648;
			}
			return newH;
		},
	},
};
</script>

<style scoped>
	.cardBG{
		background-color: #424242;
	}
	.discount-tag{
		right: 0px;
		position: absolute;
		top: 0px;
		height: 100px;
		width: 100px;
		overflow: hidden;
		z-index: 10;
	}
	.discount-tag span{
		right: -64px;
		position: absolute;
		top: 32px;
		height: 24px;
		width: 206px;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
		background-color: var(--v-primary-base);;
		color: #fff;
		text-align: center;
	}

	.tourPrice{
		color: var(--v-primary-base);
	}

	.tourImg{
		width: 100%;
		height: auto;
	}
	.tourTitle{
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 1;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		width: auto;
		padding-bottom: 0px;
		cursor: pointer;
	}

	.tourDetails-card {
		overflow: hidden;
		position: relative;
		line-height: 1.2em;
		max-height: 3.6em;
		text-align: justify;
		margin-right: -1em;
		padding-right: 1em;
	}
	.tourDetails-card+.tourDetails-card{
		margin-top:10px;
	}
	.tourDetails-card:before {
		content: '...';
		position: absolute;
		right: 0;
		bottom: 0;
	}
	.tourDetails-card:after {
		content: '';
		position: absolute;
		right: 0;
		width: 1em;
		height: 1em;
		margin-top: 0.2em;
		background: white;
	}

	.card-content{
		width: auto !important;
		padding-top: 16px;
	}
	.cardImage{
		cursor: pointer;
	}
</style>

<style lang="scss" scoped>
	.tourCard-zoom{
		transform: scale(1);
		transition: all 0.3s ease-in-out;
		@media (min-width: 960px) {
			&:hover {
				transform: scale(1.075);
			}
		}
	}
	.cardImage-height{
		height: auto !important;
	}
</style>
